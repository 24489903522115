html, body {
  font-family: "Poppins", sans-serif;
  color: #000;
}
a {
  text-decoration: none;
}

.btn-outline-info {
  --bs-btn-color: #00a7d4;
  --bs-btn-border-color: #00a7d4;
  --bs-btn-hover-bg: #00a7d4;
  --bs-btn-hover-border-color: #00a7d4;
  --bs-btn-active-bg: #00a7d4;
  --bs-btn-active-border-color: #00a7d4;
  --bs-btn-disabled-color: #00a7d4;
  --bs-btn-disabled-border-color: #00a7d4;
}
.col-md-4-custom {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
}
.form-floating>.form-control, .form-floating>.form-control-plaintext {
  padding: .75rem 0.50rem !important;
  border-radius: 0px !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-color: #000;
}
.form-control {
  font-family: 'Poppins' !important;
  font-size: 12px !important;
  background-color: transparent !important;
}
.form-floating>label{
  font-family: 'Poppins' !important;
  font-size: 14px !important;
}
.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}
.grecaptcha-badge:hover {
  width: 256px !important;
}

.App {
  text-align: center;
}
.hide {
  display: none;
}
.brand-image-start {
  height: 75px;
}
.custom-bar-contact {
  background-color: #405d9f;
  padding: 10px 20px;
  color: #fff;
  font-size: 11px;
  text-align: right;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.custom-bar {
  background-color: #fff;
  color: #000;
  font-size: 15px;
}
.main-title {
  color: #000;
  font-size: 45px;
  font-weight: 400;
  text-align: left;
  line-height: 53px;
}
.main-title-bold {
  color: #00a7d4;
  font-weight: 600;
}
.main-subtitle {
  color: #000;
  font-size: 17px;
  font-weight: 300;
  text-align: left;
  padding-top: 20px;
}
.space-bar {
  height: 101px;
}
.main-home {
  background-color: #eaf1ff;
}
.main-image {
  background-image: url(./img/doctores.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;
  height: 550px;
}
.section {
  padding: 100px 0;
}
.section-title {
  font-size: 50px;
  line-height: 55px;
  font-weight: 600;
  text-align: left;
}
.section-desc {
  color: #8791a5;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
  padding-top: 20px;
}
.img-70 {
  width: 70%;
}
.img-80 {
  width: 80%;
}
.img-100 {
  width: 100%;
}
.us-container {
  text-align: center;
  padding: 30px;
}

.home-data {
  position: relative;
  bottom: 0px;
  background-color: #eaf1ff;
  border-radius: 60px;
  width: 60%;
  padding: 40px 40px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.title-number { 
  color: #00a7d4;
  font-size: 45px;
  font-weight: 600;
}
.title-desc { 
  color: #424040;
  font-size: 15px;
  font-weight: 300;
}
.service-icon-container {
  background-color: #c8e9fa;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.service-icon {
  color: #00a8fc;
  font-size: 20px;
  position: relative;
  top: 7px;
}
.service-title {
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding-top: 15px;
}
.service-desc {
  color: #8791a5;
  font-size: 13px;
  font-weight: 300;
  text-align: left;
  padding-top: 10px;
}
.service-col {
  padding-bottom: 20px;
}
.important-desc {
  position: relative;
  background-color: #405d9f;
  padding: 30px;
  color: #fff;
  font-size: 15px;
  text-align: center;
  border-radius: 25px;
  width: 80%;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
.dots-background {
  background-image: url(./img/dots.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.extra-title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding-top: 15px;
}
.extra-desc {
  color: #8791a5;
  font-size: 13px;
  font-weight: 300;
  text-align: center;
  padding-top: 10px;
}
.extra-container{
  width: 100%;
  padding: 25px;
  border-radius: 40px;
  border: 2px solid #405d9f;
}
.extra-container-principal{
  background-color: #405d9f;
  border: 2px solid #fff;
}
.extra-icon {
  height: 50px;
  margin-bottom: 5px;
}
.section-color-gray{
  background-color: #eeeeee;
}
.contact-container {
  text-align: left;
  font-size: 15px;
  font-weight: 300;
}
.form-style {
  padding: 0 30px;
}
.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 13px;
  background-color: #405d9f;
  color: #fff !important;
}
.client-logo {
  width: auto;
  height: 100px;
}
